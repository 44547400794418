<template>
  <div>
    <h1>Brianna<br>DelValle</h1>
    <p>I'll put something here eventually.</p>
  </div>
</template>

<script>
export default {
  name: 'Index',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h1 {
  font-family: "Monoton", sans-serif;
  text-transform: uppercase;
  font-size: 6em;
  font-weight: 400;
}

p {
  display: none;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 1.5em;
  }
}
</style>
